export const PublicRoutes = {

}

export const PrivateRoutes = {
  DASHBOARD: '/dashboard',
  INSURANCE: '/insurance',
  CONVERSION_INDICATORS: '/conversion-indicators',
  TRADEIN_SALES: '/tradein/sales',
  TRADEIN_TRAFFIC: '/tradein/traffic',
  PHONECHECK: '/phonecheck',
  PREVENTA: '/preventas',
  DASHBOARDBSALE: '/dashboardBsale',
  DASHBOARDBSALEV2: '/dashboardBsaleV2',
  GOALSBSALE: '/goalsBsale',
  SALESBSALE: '/salesBsale',
  OPERACIONES: '/operaciones'
}
