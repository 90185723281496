import React, { useState } from 'react'
import { Button, Divider, Grid, TextField, Typography } from '@mui/material'
import {  dailyWeights, GoalsTableProps } from '@models/goalsBsale'
import { useSnackbar } from 'notistack';

interface WeeklyWeightingProps extends GoalsTableProps {
  dailyWeights: dailyWeights
  setDailyWeights: React.Dispatch<React.SetStateAction<dailyWeights>>
}

function WeeklyWeighting ({ state, setState, dailyWeights, setDailyWeights }: WeeklyWeightingProps) {
  const [inputError, setInputError] = useState<boolean[]>(new Array(dailyWeights.weeklyWeights!.length).fill(false))

  const weights = dailyWeights.weeklyWeights || []
  const { enqueueSnackbar } = useSnackbar()
  console.log('weights =>', weights)
  
  const dayOfTheWeek = [
    { 1: 'Lunes' },
    { 2: 'Martes' },
    { 3: 'Miércoles' },
    { 4: 'Jueves' },
    { 5: 'Viernes' },
    { 6: 'Sábado' },
    { 7: 'Domingo' }
  ]

  const checkForError = (weights: { weight: number }[]) => {
    const individualErrors = weights.map(day => day.weight < 0)
  
    const totalWeight = weights.reduce((acc, weight) => acc + Math.abs(weight.weight), 0)
    const totalExceedsError = totalWeight > 100
  
    return { individualErrors, totalExceedsError }
  }

  const handleDayWeight = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newWeight = Number(event.target.value)

    const updatedWeights: any[] = [...dailyWeights.weeklyWeights!]
    console.log('updatedWeights', updatedWeights)
    updatedWeights[index] = { ...updatedWeights[index], newWeight }


    const assignedWeight = updatedWeights.reduce((sum, day) => sum + day.newWeight, 0)
    console.log('assignedWeight', assignedWeight)

    const remainingWeight = 100 - assignedWeight

    const unmodifiedDays = updatedWeights.filter(day => !day.newWeight)
    console.log('unmodifiedDays', unmodifiedDays)

    if (unmodifiedDays.length > 0) {
        const weightPerDay = parseFloat((remainingWeight / unmodifiedDays.length).toFixed(2))

        updatedWeights.forEach((day, idx) => {
            if (!updatedWeights[idx].newWeight) {
                updatedWeights[idx] = { ...day, weight: weightPerDay }
            }
        })
    }

    updatedWeights.forEach((day, idx) => {
        if (day.newWeight) {
            updatedWeights[idx] = { ...day, weight: day.newWeight }
        }
    })

    setDailyWeights({ weeklyWeights: updatedWeights })

    const { individualErrors, totalExceedsError } = checkForError(updatedWeights)
    console.log('errorStates', { individualErrors, totalExceedsError })
    setInputError(individualErrors);
}

  const handlePreview = () => {

    const { totalExceedsError } = checkForError(dailyWeights.weeklyWeights!)
    if (totalExceedsError) {
      enqueueSnackbar('La suma total de los pesos no puede exceder 100%.', { variant: 'error' });
      return
    }

    const copyWeeks = [...state.weeks]

    copyWeeks.forEach((week) => {
        const totalWeekGoal = week.days.reduce((acc, day) => day.newGoal === 0 ? acc + day.goal : acc, 0)
        const activeWeights = week.days.reduce((acc, day, index) => {
          if (day.newGoal === 0) acc[index] = weights[index].weight || 1
          return acc
        }, {} as { [key: number]: number })
        
        const totalActiveWeights = Object.values(activeWeights).reduce((sum, weight) => sum + weight, 0)

        week.days.forEach((day, index) => {
          if (day.newGoal === 0) {
            const adjustedWeight = (activeWeights[index] / totalActiveWeights) * 100
            day.goal = totalWeekGoal * adjustedWeight / 100
          }
        })
    })
  
    setDailyWeights({ weeklyWeights: weights })
    setState(prevState => ({ ...prevState, weeks: copyWeeks, dailyWeights: { ...weights } }))
   }
  
  return (
    <div>
      <Divider />
        <Grid container columnSpacing={4} rowSpacing={2} sx={{ py: 3 }}>
          {weights.map((day, i) => (
            <Grid item xs={7} md={4} lg={2} key={i} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography sx={{ width: '100%', backgroundColor: '#1976D2', color: 'white', padding: 0.5, textAlign: 'center', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>{Object.values(dayOfTheWeek[i])[0]}</Typography>
            <TextField
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  '& .Mui-disabled': { WebkitTextFillColor: '#1976D2 !important' }
                }
              }}
              inputProps={{ min: 1, max: 100, style: { textAlign: 'center' } }}
              value={weights[i].newWeight ? weights[i].newWeight : ''}
              placeholder={weights[i].weight.toString()}
              size='small'
              type='number'
              onChange={(event) => handleDayWeight(event, i)}
              error={inputError[i]}
              helperText={inputError[i] ? 'El valor no puede ser negativo' : ''}
            />
          </Grid>
        ))}
        <Grid 
        item 
        xs={12} 
        md={4} 
        lg={2} 
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '100%', maxWidth: '200px' }}
          onClick={() => handlePreview()}
        >
          Previsualizar
        </Button>
      </Grid>
    </Grid>
    </div>
  )
}

export default WeeklyWeighting