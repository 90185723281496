import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Card, CardHeader, Typography } from '@mui/material';
import { DashboardBsaleV2ContextType, useDashboardBsaleV2Context } from '@pages/DashboardBsaleV2/context/dashboardBsaleV2.context';

  function CustomTooltip({ active, payload, label } : any) {
    
    if (active && payload && payload.length) {
      return (
        <Box sx={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          padding: "5px",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
        }}>
          <div style={{display: "flex", alignItems: "center"}}>
          <span style={{ 
            backgroundColor: payload[0].color, 
            width: "20px", 
            height: "4px", 
            marginRight: "5px",
            padding: "0px"
          }}/>
          <p>{`${payload[0].payload.fecha} ${payload[0].value}`}</p>
          </div>
          {payload[1] && (
            <div style={{display: "flex", alignItems: "center"}}>
            <span style={{ 
              backgroundColor: payload[1].color, 
              width: "20px", 
              height: "4px", 
              marginRight: "5px",
                          padding: "0px"
          }}/>
            <p>{`${payload[1].payload.fecha2} ${payload[1].value}`}</p>
          </div>
          )}
        </Box>
      );
    }
  
    return null;
  }

function TotalSale () {
    const { ctx } = useDashboardBsaleV2Context() as DashboardBsaleV2ContextType

  return (
    <Card sx={{height: 300}}>
      <CardHeader title={<Typography variant='h6'>Unidades</Typography>} />
      <ResponsiveContainer width="100%" height="80%">
        <LineChart
          width={500}
          height={300}
          data={ctx.totalSales}
          margin={{
            top: 15,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false}/>
          <XAxis dataKey="fecha" axisLine={false} tickLine={false} tickMargin={15}/>
          <YAxis axisLine={false} tickLine={false} tickMargin={15}/>
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ paddingBlock: 10 }}/>

          {ctx.isFromLonger ? (
            <>
          <Line type="monotone" dataKey="totalUnidades" stroke="#8884d8" strokeWidth={2} dot={false} activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="totalUnidades2" stroke="#82ca9d" strokeDasharray="3 3" dot={false}/>
            </>
          ) : (
            <>
          <Line type="monotone" dataKey="totalUnidades2" stroke="#8884d8" strokeWidth={2} dot={false} activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="totalUnidades" stroke="#82ca9d" strokeDasharray="3 3" dot={false}/>
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default TotalSale
