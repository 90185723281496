import { DBOARD_FORMATEND, DBOARD_FORMATSTART } from '@constants/dashboard.constants'
import { DboardApi } from '@services/index'
import moment from 'moment'
import { createContext, useContext, useEffect, useState } from 'react'


type totalsales = {
    month: any,
    total_ventas_2024: any,
    total_devoluciones_2024: any,
    total_ventas_2023: any,
    total_devoluciones_2023: any
}

type salesSbuUnit = {
  salesBySbu: any
  salesByUnit: any
}

type returnsSbuUnit = {
  returnsBySbu: any
  returnsByUnit: any
}

type unitSales = {
  month: any,
  unidades_vendidas_2024: any,
  unidades_devueltas_2024: any,
  unidades_vendidas_2023: any,
  unidades_devueltas_2023: any
}

export type salesQuarter = {
  fecha: any,
  total_vendido_current: any
  total_devoluciones_current: any,
  total_neto_2024: any,
  total_vendido_last_year: any,
  total_devoluciones_last_year: any,
  total_neto_2023: any
}

export type salesLastDays = {
  fecha_current: any,
  total_vendido_current: any,
  total_devoluciones_current: any,
  total_neto_2024: any,
  fecha_last_year: any,
  total_vendido_last_year: any,
  total_devoluciones_last_year: any,
  total_neto_2023: any,
}



export type unitLastDays = {
  fecha: any,
  unidades_retornadas_current: any,
  unidades_retornadas_last_year: any,
  total_current: any,
  unidades_vendidas_current: any,
  unidades_vendidas_last_year: any,
  total_last_year: any
}

type salesByChannel = {
  name: any,
  ventas: any,
  devoluciones: any,
  total: any
}
type channelId = {
  sbu: string, 
  id_channel: number , 
  name : string ,
  margin : number
}

type unitsByChannel = {
  name: any,
  ventas: any,
  devoluciones: any,
}
type officeId = {
  id: any,
  office_id: number,
  office_name: string,
  variant_id:number,
  name:string,
  sku: string,
  product_type: string,
  quantity: number,
  averageCost: number
}
type total = {
totalStock: number,
totalAmount: number,
}

type typeOfReturns = {
  id: any
  name: any
  unidades: any
  total: any
}

type typeProduct =  { 
  costoPorMeta: number,
   costo: number, 
   tipoProducto: string,
    id: number
   }
type daysInventory =  { 
  daysInvetory: number,
   costo: number, 
   tipoProducto: string,
    id: number
   }
type marginSBU =  { 
   id: number,
   sbu: string ,  
   margen : number
   }
type totalYear =  { 
  total_2023: string,
  total_2024: string ,  
  variacion_porcentual : string
   }
type skuPurchase =  { 
 
   sku: string ,  
 
   }
   type skuPruchase =  { 
    sku:string ,
      name :string,
       created_at : string ,
        type : string
    }
   type returnOffice =  { 
      office: string,
      total_devoluciones : number,
      total_monto_devoluciones : number

    }
type filter = {
  from: string | null
  to: string | null
  
  sbu: {id: number, description: string}[] | null
  selectedChannel: {id: number, name: string} | null
  selectedSbu: {id: number, description: string} | null
  loading: boolean
  download: boolean
  download2: boolean
  switch: boolean
  weekInitial: number
  weekEnd: number,

}

export interface Operations {
  filter: filter
  lastUpdate: any
  excludeNC: Number[]
  channel: string,

  totalSales: totalsales[]
  unitSales: unitSales[]
  typeOfReturns: typeOfReturns[]
  salesByChannel: salesByChannel[]
  unitsByChannel: unitsByChannel[]
  salesQuarter: salesQuarter[]
  salesLastDays: salesLastDays[]
 
  unitLastDays: unitLastDays[]
  salesSbuUnit: salesSbuUnit
  returnsSbuUnit: returnsSbuUnit

 
  loading: boolean,
  officeId:  officeId[],
  total: total,
  selectedOffice: string,
  typeProduct: typeProduct[],
  daysInventory: daysInventory[],
  marginSBU: marginSBU[],
  channelId: channelId[]
  skuPruchase: skuPruchase[],
  skuPurchase30: skuPurchase[],
  returnOffice: returnOffice[],

}

export type OperationsContextType = {
    ctx: Operations;
    saveCtx: (ctx: Operations) => void;
  };

export const OperationsContext = createContext<OperationsContextType | null>(null)

export const OperationsProvider = ({ children } : any) => {
  const { ctx, setCtx } = useOperationsProviderStore()
  const saveCtx = (_ctx: Operations) => {
    setCtx(_ctx)
  }

  useEffect(() => {
    const parameters = {
      from: moment().format(DBOARD_FORMATSTART),
      to: moment().format(DBOARD_FORMATEND),
      channelId: 1,
      sbuId: null,
      weekInitial: 1,
      weekEnd: 12,
      officeId: 0,
      total : 0,
      typeProduct: 'Todos',
      daysInvetory: 'Todos',
      marginSBU: 0,
      skuPruchase: 1,
      skuPurchase30: 1,
      returnOffice: 0,



    }
    const getAllData = async () => {
      setCtx({ ...ctx, loading: true })

      const res = await Promise.all([
        DboardApi.getTotalSales(parameters).then(res => res.data),
        DboardApi.getAllChannels().then(res => res.items.map((ele: any) => { return { id: ele.id, name: ele.name } })),
        DboardApi.getAllSbus().then(res => res.items.map((ele: any) => { return { id: ele.id, description: ele.description } })),
        DboardApi.getStockByOffice(parameters.officeId).then(res => res.data),
        DboardApi.getTotalStockByOffice(parameters.officeId).then(res => res.data.map((ele: any) => { return { totalStock: ele.total_stock, totalAmount: ele.total_average_cost } })),
        DboardApi.daysOfInventoryByGoals().then((res: any) => res.data.map((ele: any) => { return { costoPorMeta: ele.days_of_inventory, costo: ele.cost , tipoProducto : ele.product_type, id : ele.id } })),
        DboardApi.daysOfInventoryBySales().then((res: any) => res.data.map((ele: any) => { return { daysInvetory: ele.days_of_inventory, costo: ele.cost , tipoProducto : ele.product_type, id : ele.id } })),
        DboardApi.getMarginBySbu().then((res: any) => res.data.map((ele: any) => { return { id: ele.id, sbu: ele.product_type ,  margen : ele.average_margin_percentage } })),
        DboardApi.getMarginByChannel().then((res: any) => res.data.map((ele: any) => { return {  id_channel: ele.sales_channel ,  name : ele.name , margen : ele.average_margin_percentage } })),
        DboardApi.getSkusWithoutPurchaseIn60Days().then((res: any) => res.data.map((ele: any) => { return { sku: ele.sku ,  name : ele.name , created_at : ele.created_at , type : ele.type} })),
        DboardApi.getSkusWithoutSaleIn30Days().then((res: any) => res.data.map((ele: any) => { return { sku: ele.sku } })),
        DboardApi.getRefundsByOffice(parameters.returnOffice).then((res: any) => res.data.map((ele: any) => { return { office: ele.office ,  total_devoluciones : ele.total_devoluciones , total_monto_devoluciones : ele.total_monto_devoluciones } })),
   
   
   
      ])

      setCtx({
        ...res[0],
        officeId: res[3],
        total: res[4],
        typeProduct:  res[5],
        daysInventory: res[6],
        marginSBU: res[7],
        channelId: res[8],
        skuPruchase: res[9],
        skuPurchase30: res[10],
        returnOffice: res[11],
       
        filter: {
          ...ctx.filter,
          from: moment().format(DBOARD_FORMATSTART),
          to: moment().format(DBOARD_FORMATEND),
          channel: [{ id: 0, name: 'Todos' }, ...res[1]],
          sbu: [{ id: 0, description: 'Todos' }, ...res[2]],
          weekInitial: 1,
          weekEnd: 12,
       
        
        },
        loading: false,
       
      })
    }
    getAllData()
  }, []) //eslint-disable-line

  return <OperationsContext.Provider value={{ ctx, saveCtx }}>{children}</OperationsContext.Provider>
}

const useOperationsProviderStore = () => {
  const [ctx, setCtx] = useState<Operations>({
    filter: {
      from: null,
      to: null,
    
      sbu: null,
      selectedChannel: { id: 0, name: 'Todos' },
      selectedSbu: { id: 0, description: 'Todos' },
      loading: false,
      download: false,
      download2: false,
      switch: false,
      weekInitial: 0,
      weekEnd: 0,
    },
    lastUpdate: '',
    excludeNC: [],
    channel: '',
  
    totalSales: [],
    unitSales: [],
    typeOfReturns: [],
    salesByChannel: [],
    unitsByChannel: [],
    salesQuarter: [],
    salesLastDays: [],
  
    unitLastDays: [],
    salesSbuUnit: {
      salesBySbu: 0,
      salesByUnit: 0
    }, 

    returnsSbuUnit: {
      returnsBySbu: 0,
      returnsByUnit: 0
    },
   
 
    loading: false,
    officeId: [],
    total: {
      totalStock: 0,
      totalAmount: 0,
    },
    
    selectedOffice: 'Todos',
    typeProduct: [] ,
    daysInventory: [],
    marginSBU: [],
    channelId: [],
    skuPruchase: [],
    skuPurchase30: [],
    returnOffice: [],
    
  })

  return {
    ctx,
    setCtx
  }
}

export const useOperationsContext = () => {
  const context = useContext(OperationsContext)
  if (context === undefined) {
    throw new Error('OperationsContext must be used within a OperationsProvider')
  }
  return context
}
