import { Grid, } from '@mui/material'
import TotalSaleCharts from './components/TotalSaleCharts/TotalSaleCharts'
import { OperationsContextType, useOperationsContext } from './context/Operations.context'

import Cards from './components/Cards'
import Filter from '@pages/Operations/components/Filter'



const Operacions = () => {
  const { ctx } = useOperationsContext() as OperationsContextType
console.log(ctx
     
)
  return (
    <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        <Grid item xs={12}>
        <Filter />
   </Grid>
   <Grid item xs={12}>
        <Cards />
   </Grid>
   <Grid item xs={12}>
        <TotalSaleCharts />
        </Grid>
        
         </Grid>
      )
    }
    
    export default Operacions
      
 
       






   
    
  
         
        

       


        
    
