import { Grid, Box } from '@mui/material'
import DaysInventoryByGoal from './components/daysInventoryByGoal'
import InventoryForDays from './components/InventoryForDays'

import MarginSold from './components/MarginSold'
import Obsolescencia from './components/Obsolescencia'
import TotalAmountStore from './components/TotalAmountStore'
import TotalInventoryStore from './components/TotalInventoryStore'
import VariationSoldYears from './components/VariationSoldYears'
import Returns from './components/Returns'

function TotalSaleCharts () {
  return (
    <>
      <Box mb={2}>
        <Grid item xs={12}>
          <TotalInventoryStore />
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid item xs={12}>
          <TotalAmountStore />
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid item xs={12}>
          <InventoryForDays />
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid item xs={12}>
          <DaysInventoryByGoal />
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid item xs={12}>
          <MarginSold />
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid item xs={12}>
        <Obsolescencia />
      
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid item xs={12}>
        <VariationSoldYears /> 
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid item xs={12}>
        <Returns />
        </Grid>
      </Box>
      
    </>
  )
}

export default TotalSaleCharts
