import AverageSales from './components/AverageSales'
import TotalSale from './components/TotalSale'
import UnitSale from './components/UnitSale'

function TotalSaleCharts () {
  return (
    <>
      <TotalSale />
      <UnitSale />
      <AverageSales />
    </>
  )
}

export default TotalSaleCharts