import { Grid } from '@mui/material'
import TotalSale from './components/TotalSale'
import AverageSales from './components/AverageSales'
import UnitSale from './components/UnitSale'

function TotalSaleCharts () {
  return (

    <Grid container rowSpacing={2.5} columnSpacing={2.75}>
      <Grid item xs={12} md={4}>
        <TotalSale />
      </Grid>
      <Grid item xs={12} md={4}>
        <UnitSale />
      </Grid>
      <Grid item xs={12} md={4}>
        <AverageSales />
      </Grid>
    </Grid>

  )
}

export default TotalSaleCharts
