import React, { useEffect, useState } from 'react'
import { Card, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import { OperationsContextType, useOperationsContext } from '@pages/Operations/context/Operations.context'

import { DboardApi } from '@services/index'
function VariationSoldYears() {
  const { ctx } = useOperationsContext() as OperationsContextType
  const [hide, setHide] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [chartData, setChartData] = useState<any>({
    salesChannel: 0,
    idSbu: 0,
    fromYear: 2023,
    toYear: 2024,
  })
  

  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const data = hide.filter((ele: any) => ele !== e.id)
      setHide(data)
    } else {
      setHide([...hide, e.id])
    }
  }

  const handleSBUChange = (event: SelectChangeEvent<string>) => {
    const newSBU = event.target.value
    setChartData((prevState: any) => ({
      ...prevState,
      idSbu: newSBU,
    }))
  }

  const handleChannelChange = (event: SelectChangeEvent<string>) => {
    const newChannel = event.target.value
    setChartData((prevState: any) => ({
      ...prevState,
      salesChannel: newChannel,
    }))
  }

  const formatInMillions = (value: number) => {
    return (value / 1000000).toFixed(2) + 'M'
  }

  const fetchData = async () => {
    try {
      const res = await DboardApi.getPercentageVariation(chartData)
  
      setData(res.data) 
    
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
 
      fetchData()
    
  }, [chartData.salesChannel, chartData.idSbu])

 
  const formattedData = data.map((item: any) => ({
    year: item.year,
    total_2023: item.total_2023,
    total_2024: item.total_2024,
    diferencia: item.variacion_porcentual,
  })) 

 

  return (
    <Card sx={{ height: 600 }}>
      <CardHeader
        title={
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="h6">Variación Porcentual entre 2023 y 2024</Typography>
            </Grid>
            <Grid item container spacing={2} justifyContent="flex-end" alignItems="center">
              <Grid item>
                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel>SBU</InputLabel>
                  <Select value={chartData.idSbu} onChange={handleSBUChange}>
                    {ctx.marginSBU.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.sbu}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel>Channel</InputLabel>
                  <Select value={chartData.salesChannel} onChange={handleChannelChange}>
                    {ctx.channelId.map((item: any) => (
                      <MenuItem key={item.id_channel} value={item.id_channel}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        }
      />

  
        <ResponsiveContainer width="100%" height="80%">
          <ComposedChart data={formattedData} margin={{ top: 20, right: 20, bottom: 20, left: 50 }}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="year" />
            <YAxis tickFormatter={formatInMillions} />
            <Tooltip
              formatter={(value: number, name, props) => (
                <div>
                  <div>{`Cantidad: ${formatInMillions(value)}`}</div>
                  <div>{`Variación porcentual: ${props.payload.diferencia}%`}</div>
                </div>
              )}
            />
            <Legend wrapperStyle={{ cursor: 'pointer' }} onClick={handleLenged} />
            <Bar dataKey="total_2023" name="Ventas 2023" legendType="circle" fill="#413ea0" barSize={30}>
              <LabelList dataKey="total_2023" position="top" fontSize={12} formatter={formatInMillions} />
            </Bar>
            <Bar dataKey="total_2024" name="Ventas 2024" legendType="circle" fill="#ff7300" barSize={30}>
              <LabelList dataKey="total_2024" position="top" fontSize={12} formatter={formatInMillions} />
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
  
    </Card>
  )
}

export default VariationSoldYears